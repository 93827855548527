import { getOrCreateDataObject } from 'o365.vue.ts';

export default getOrCreateDataObject({
    id: 'dsOPerson',
    viewName: 'sviw_System_MyPerson',
    distinctRows: false,
    allowUpdate: false,
    allowInsert: false,
    allowDelete: false,
    appendData: false,
    disableLayouts: true,
    selectFirstRowOnLoad: true,
    fields: [{
        name: 'PrimKey',
        type: 'uniqueidentifier'
    },
    {
        name: 'ColorTheme',
        type: 'string'
    },
    {
        name: 'ID',
        type: 'number',
        sortOrder: 1,
        sortDirection: 'asc'
    },
    {
        name: 'Name',
        type: 'string'
    },
    {
        name: 'NotificationEmails',
        type: 'string'
    },
    {
        name: 'NotificationEmailsName',
        type: 'string'
    }],
    clientSideHandler: false,
    maxRecords: 1,
    dynamicLoading: false
});
